import { default as _91_46_46_46all_93AorG6IFf9tMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/[...all].vue?macro=true";
import { default as _91id_93BDGBXA7QHlMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/about/[id].vue?macro=true";
import { default as indexQoSTiti09VMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/about/index.vue?macro=true";
import { default as indexL5ytV8WN2GMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/account/index.vue?macro=true";
import { default as listWI8CRlR8mtMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/action/components/list.vue?macro=true";
import { default as searchvWuyVPiQBpMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/action/components/search.vue?macro=true";
import { default as index3C7CEBButyMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/action/index.vue?macro=true";
import { default as serializehAGO91pk8mMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/common/serialize.ts?macro=true";
import { default as table_setting63vNJ9NXcVMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/common/table_setting.ts?macro=true";
import { default as add_45blockTHySI3EeihMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/components/add-block.vue?macro=true";
import { default as indexVJbZW9yYpQMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/components/audit-status/index.vue?macro=true";
import { default as indexln1hAxii7qMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/components/table-setting/index.vue?macro=true";
import { default as info1Tx5UNVT18Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/download/components/info.vue?macro=true";
import { default as index3RlR1OOuFzMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/download/index.vue?macro=true";
import { default as listEkbIRyMbZqMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/components/list.vue?macro=true";
import { default as searchzPr6ubEAliMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/components/search.vue?macro=true";
import { default as employee4aPbMLKes4Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/employee.vue?macro=true";
import { default as indexZyVDoN6vj5Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/index.vue?macro=true";
import { default as activity6rPQlC5CRJMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/activity.vue?macro=true";
import { default as handle0G6Cqn3v5NMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/handle.vue?macro=true";
import { default as infoMEanwPWeklMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/info.vue?macro=true";
import { default as projectIkbqQqALWIMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/project.vue?macro=true";
import { default as indexJgD86vCkxzMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/index.vue?macro=true";
import { default as _32activity_45joinjlQtkR4bzDMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/ activity-join.vue?macro=true";
import { default as activity_45detailtp97xBTRHcMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-detail.vue?macro=true";
import { default as activity_45excellence_45join_45recordswcTU3d2oKOMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-excellence-join-records.vue?macro=true";
import { default as activity_45excellence_45join3LwP18JdSDMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-excellence-join.vue?macro=true";
import { default as activity_45listyAXwV4UoKDMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-list.vue?macro=true";
import { default as settingsSasVHwUaSXMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/settings.ts?macro=true";
import { default as indexBJnjY1AlSgMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/manager/index.vue?macro=true";
import { default as activity_45score_45detailtpPeeDI4feMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/modal/activity-score-detail.vue?macro=true";
import { default as activity_45score_45listjmjIaAqEigMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/modal/activity-score-list.vue?macro=true";
import { default as business_45modalLBsrUsfWW4Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/modal/business-modal.vue?macro=true";
import { default as indexQ3SFD1KAetMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/other-info/index.vue?macro=true";
import { default as screen_45setting8Mbuh1Gad9Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/other-info/screen-setting.ts?macro=true";
import { default as list7WgzL6oXWpMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/project/components/list.vue?macro=true";
import { default as searchMT2buZI8JfMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/project/components/search.vue?macro=true";
import { default as indexS5UF2X3uaAMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/project/index.vue?macro=true";
import { default as merge_45table_45containerZMfjqSYbVpMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/merge-table-container.vue?macro=true";
import { default as indexYr8ufy3mwAMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/other-info/index.vue?macro=true";
import { default as screen_45settingwemJeiVsMeMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/other-info/screen-setting.ts?macro=true";
import { default as index9EpLQyFeYKMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/table-merge/index.vue?macro=true";
import { default as indexTNtkLgDDSRMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/index.vue?macro=true";
import { default as normalizeel8ud9dp5GMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/normalize.ts?macro=true";
import { default as settingTmbCALSlEHMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/setting.ts?macro=true";
import { default as constantsoaHSiljH8FMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/application/common/constants.ts?macro=true";
import { default as settingsrbqpgMMmoeMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/application/common/settings.ts?macro=true";
import { default as utilsVHtYjnkI2eMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/application/common/utils.ts?macro=true";
import { default as indexeLcsuN75mMMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/application/components/application-form/index.vue?macro=true";
import { default as index6IIbXirAxsMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/application/components/application-notice/index.vue?macro=true";
import { default as indexHeCOGZoiLxMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/application/index.vue?macro=true";
import { default as _91id_93yrcc7HFKqOMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/content/[id].vue?macro=true";
import { default as _91id_93Uuk8q5VyhxMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/content/post/[id].vue?macro=true";
import { default as credit_45tablehS9PKX5NNVMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/credit/components/credit-table.vue?macro=true";
import { default as indexI2BgtghFdoMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/credit/index.vue?macro=true";
import { default as _91id_93MoTx4btO8YMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/credit/post/[id].vue?macro=true";
import { default as placard_45dialogX8UIYm7mHRMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/credit/post/components/placard-dialog.vue?macro=true";
import { default as companyRl4pvetbneMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/company.vue?macro=true";
import { default as infinite_45listYmWlxadOELMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/infinite-list.vue?macro=true";
import { default as party_45linerTBvroL9eRMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/party-line.vue?macro=true";
import { default as rulesQ17jGKFw01Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/rules.vue?macro=true";
import { default as services_45listQ8EEcGAPcPMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/services-list.vue?macro=true";
import { default as index1ucIzz74iPMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/index/index.vue?macro=true";
import { default as indexTgWxjaqIo4Meta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/login/index.vue?macro=true";
import { default as indexiKEb0fHJahMeta } from "/var/jenkins_home/workspace/city-property-rating-web/pages/register/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93AorG6IFf9tMeta?.name ?? "all",
    path: _91_46_46_46all_93AorG6IFf9tMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93AorG6IFf9tMeta || {},
    alias: _91_46_46_46all_93AorG6IFf9tMeta?.alias || [],
    redirect: _91_46_46_46all_93AorG6IFf9tMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BDGBXA7QHlMeta?.name ?? "about-id",
    path: _91id_93BDGBXA7QHlMeta?.path ?? "/about/:id()",
    meta: _91id_93BDGBXA7QHlMeta || {},
    alias: _91id_93BDGBXA7QHlMeta?.alias || [],
    redirect: _91id_93BDGBXA7QHlMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/about/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQoSTiti09VMeta?.name ?? "about",
    path: indexQoSTiti09VMeta?.path ?? "/about",
    meta: indexQoSTiti09VMeta || {},
    alias: indexQoSTiti09VMeta?.alias || [],
    redirect: indexQoSTiti09VMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5ytV8WN2GMeta?.name ?? "admin-account",
    path: indexL5ytV8WN2GMeta?.path ?? "/admin/account",
    meta: indexL5ytV8WN2GMeta || {},
    alias: indexL5ytV8WN2GMeta?.alias || [],
    redirect: indexL5ytV8WN2GMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/account/index.vue").then(m => m.default || m)
  },
  {
    name: listWI8CRlR8mtMeta?.name ?? "admin-action-components-list",
    path: listWI8CRlR8mtMeta?.path ?? "/admin/action/components/list",
    meta: listWI8CRlR8mtMeta || {},
    alias: listWI8CRlR8mtMeta?.alias || [],
    redirect: listWI8CRlR8mtMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/action/components/list.vue").then(m => m.default || m)
  },
  {
    name: searchvWuyVPiQBpMeta?.name ?? "admin-action-components-search",
    path: searchvWuyVPiQBpMeta?.path ?? "/admin/action/components/search",
    meta: searchvWuyVPiQBpMeta || {},
    alias: searchvWuyVPiQBpMeta?.alias || [],
    redirect: searchvWuyVPiQBpMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/action/components/search.vue").then(m => m.default || m)
  },
  {
    name: index3C7CEBButyMeta?.name ?? "admin-action",
    path: index3C7CEBButyMeta?.path ?? "/admin/action",
    meta: index3C7CEBButyMeta || {},
    alias: index3C7CEBButyMeta?.alias || [],
    redirect: index3C7CEBButyMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/action/index.vue").then(m => m.default || m)
  },
  {
    name: serializehAGO91pk8mMeta?.name ?? "admin-common-serialize",
    path: serializehAGO91pk8mMeta?.path ?? "/admin/common/serialize",
    meta: serializehAGO91pk8mMeta || {},
    alias: serializehAGO91pk8mMeta?.alias || [],
    redirect: serializehAGO91pk8mMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/common/serialize.ts").then(m => m.default || m)
  },
  {
    name: table_setting63vNJ9NXcVMeta?.name ?? "admin-common-table_setting",
    path: table_setting63vNJ9NXcVMeta?.path ?? "/admin/common/table_setting",
    meta: table_setting63vNJ9NXcVMeta || {},
    alias: table_setting63vNJ9NXcVMeta?.alias || [],
    redirect: table_setting63vNJ9NXcVMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/common/table_setting.ts").then(m => m.default || m)
  },
  {
    name: add_45blockTHySI3EeihMeta?.name ?? "admin-components-add-block",
    path: add_45blockTHySI3EeihMeta?.path ?? "/admin/components/add-block",
    meta: add_45blockTHySI3EeihMeta || {},
    alias: add_45blockTHySI3EeihMeta?.alias || [],
    redirect: add_45blockTHySI3EeihMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/components/add-block.vue").then(m => m.default || m)
  },
  {
    name: indexVJbZW9yYpQMeta?.name ?? "admin-components-audit-status",
    path: indexVJbZW9yYpQMeta?.path ?? "/admin/components/audit-status",
    meta: indexVJbZW9yYpQMeta || {},
    alias: indexVJbZW9yYpQMeta?.alias || [],
    redirect: indexVJbZW9yYpQMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/components/audit-status/index.vue").then(m => m.default || m)
  },
  {
    name: indexln1hAxii7qMeta?.name ?? "admin-components-table-setting",
    path: indexln1hAxii7qMeta?.path ?? "/admin/components/table-setting",
    meta: indexln1hAxii7qMeta || {},
    alias: indexln1hAxii7qMeta?.alias || [],
    redirect: indexln1hAxii7qMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/components/table-setting/index.vue").then(m => m.default || m)
  },
  {
    name: info1Tx5UNVT18Meta?.name ?? "admin-download-components-info",
    path: info1Tx5UNVT18Meta?.path ?? "/admin/download/components/info",
    meta: info1Tx5UNVT18Meta || {},
    alias: info1Tx5UNVT18Meta?.alias || [],
    redirect: info1Tx5UNVT18Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/download/components/info.vue").then(m => m.default || m)
  },
  {
    name: index3RlR1OOuFzMeta?.name ?? "admin-download",
    path: index3RlR1OOuFzMeta?.path ?? "/admin/download",
    meta: index3RlR1OOuFzMeta || {},
    alias: index3RlR1OOuFzMeta?.alias || [],
    redirect: index3RlR1OOuFzMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/download/index.vue").then(m => m.default || m)
  },
  {
    name: listEkbIRyMbZqMeta?.name ?? "admin-employee-components-list",
    path: listEkbIRyMbZqMeta?.path ?? "/admin/employee/components/list",
    meta: listEkbIRyMbZqMeta || {},
    alias: listEkbIRyMbZqMeta?.alias || [],
    redirect: listEkbIRyMbZqMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/components/list.vue").then(m => m.default || m)
  },
  {
    name: searchzPr6ubEAliMeta?.name ?? "admin-employee-components-search",
    path: searchzPr6ubEAliMeta?.path ?? "/admin/employee/components/search",
    meta: searchzPr6ubEAliMeta || {},
    alias: searchzPr6ubEAliMeta?.alias || [],
    redirect: searchzPr6ubEAliMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/components/search.vue").then(m => m.default || m)
  },
  {
    name: employee4aPbMLKes4Meta?.name ?? "admin-employee-employee",
    path: employee4aPbMLKes4Meta?.path ?? "/admin/employee/employee",
    meta: employee4aPbMLKes4Meta || {},
    alias: employee4aPbMLKes4Meta?.alias || [],
    redirect: employee4aPbMLKes4Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/employee.vue").then(m => m.default || m)
  },
  {
    name: indexZyVDoN6vj5Meta?.name ?? "admin-employee",
    path: indexZyVDoN6vj5Meta?.path ?? "/admin/employee",
    meta: indexZyVDoN6vj5Meta || {},
    alias: indexZyVDoN6vj5Meta?.alias || [],
    redirect: indexZyVDoN6vj5Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/employee/index.vue").then(m => m.default || m)
  },
  {
    name: activity6rPQlC5CRJMeta?.name ?? "admin-index-components-activity",
    path: activity6rPQlC5CRJMeta?.path ?? "/admin/components/activity",
    meta: activity6rPQlC5CRJMeta || {},
    alias: activity6rPQlC5CRJMeta?.alias || [],
    redirect: activity6rPQlC5CRJMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/activity.vue").then(m => m.default || m)
  },
  {
    name: handle0G6Cqn3v5NMeta?.name ?? "admin-index-components-handle",
    path: handle0G6Cqn3v5NMeta?.path ?? "/admin/components/handle",
    meta: handle0G6Cqn3v5NMeta || {},
    alias: handle0G6Cqn3v5NMeta?.alias || [],
    redirect: handle0G6Cqn3v5NMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/handle.vue").then(m => m.default || m)
  },
  {
    name: infoMEanwPWeklMeta?.name ?? "admin-index-components-info",
    path: infoMEanwPWeklMeta?.path ?? "/admin/components/info",
    meta: infoMEanwPWeklMeta || {},
    alias: infoMEanwPWeklMeta?.alias || [],
    redirect: infoMEanwPWeklMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/info.vue").then(m => m.default || m)
  },
  {
    name: projectIkbqQqALWIMeta?.name ?? "admin-index-components-project",
    path: projectIkbqQqALWIMeta?.path ?? "/admin/components/project",
    meta: projectIkbqQqALWIMeta || {},
    alias: projectIkbqQqALWIMeta?.alias || [],
    redirect: projectIkbqQqALWIMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/components/project.vue").then(m => m.default || m)
  },
  {
    name: indexJgD86vCkxzMeta?.name ?? "admin-index",
    path: indexJgD86vCkxzMeta?.path ?? "/admin",
    meta: indexJgD86vCkxzMeta || {},
    alias: indexJgD86vCkxzMeta?.alias || [],
    redirect: indexJgD86vCkxzMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/index.vue").then(m => m.default || m)
  },
  {
    name: _32activity_45joinjlQtkR4bzDMeta?.name ?? "admin-index-modal- activity-join",
    path: _32activity_45joinjlQtkR4bzDMeta?.path ?? "/admin/modal/%20activity-join",
    meta: _32activity_45joinjlQtkR4bzDMeta || {},
    alias: _32activity_45joinjlQtkR4bzDMeta?.alias || [],
    redirect: _32activity_45joinjlQtkR4bzDMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/ activity-join.vue").then(m => m.default || m)
  },
  {
    name: activity_45detailtp97xBTRHcMeta?.name ?? "admin-index-modal-activity-detail",
    path: activity_45detailtp97xBTRHcMeta?.path ?? "/admin/modal/activity-detail",
    meta: activity_45detailtp97xBTRHcMeta || {},
    alias: activity_45detailtp97xBTRHcMeta?.alias || [],
    redirect: activity_45detailtp97xBTRHcMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-detail.vue").then(m => m.default || m)
  },
  {
    name: activity_45excellence_45join_45recordswcTU3d2oKOMeta?.name ?? "admin-index-modal-activity-excellence-join-records",
    path: activity_45excellence_45join_45recordswcTU3d2oKOMeta?.path ?? "/admin/modal/activity-excellence-join-records",
    meta: activity_45excellence_45join_45recordswcTU3d2oKOMeta || {},
    alias: activity_45excellence_45join_45recordswcTU3d2oKOMeta?.alias || [],
    redirect: activity_45excellence_45join_45recordswcTU3d2oKOMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-excellence-join-records.vue").then(m => m.default || m)
  },
  {
    name: activity_45excellence_45join3LwP18JdSDMeta?.name ?? "admin-index-modal-activity-excellence-join",
    path: activity_45excellence_45join3LwP18JdSDMeta?.path ?? "/admin/modal/activity-excellence-join",
    meta: activity_45excellence_45join3LwP18JdSDMeta || {},
    alias: activity_45excellence_45join3LwP18JdSDMeta?.alias || [],
    redirect: activity_45excellence_45join3LwP18JdSDMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-excellence-join.vue").then(m => m.default || m)
  },
  {
    name: activity_45listyAXwV4UoKDMeta?.name ?? "admin-index-modal-activity-list",
    path: activity_45listyAXwV4UoKDMeta?.path ?? "/admin/modal/activity-list",
    meta: activity_45listyAXwV4UoKDMeta || {},
    alias: activity_45listyAXwV4UoKDMeta?.alias || [],
    redirect: activity_45listyAXwV4UoKDMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/activity-list.vue").then(m => m.default || m)
  },
  {
    name: settingsSasVHwUaSXMeta?.name ?? "admin-index-modal-settings",
    path: settingsSasVHwUaSXMeta?.path ?? "/admin/modal/settings",
    meta: settingsSasVHwUaSXMeta || {},
    alias: settingsSasVHwUaSXMeta?.alias || [],
    redirect: settingsSasVHwUaSXMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/index/modal/settings.ts").then(m => m.default || m)
  },
  {
    name: indexBJnjY1AlSgMeta?.name ?? "admin-manager",
    path: indexBJnjY1AlSgMeta?.path ?? "/admin/manager",
    meta: indexBJnjY1AlSgMeta || {},
    alias: indexBJnjY1AlSgMeta?.alias || [],
    redirect: indexBJnjY1AlSgMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/manager/index.vue").then(m => m.default || m)
  },
  {
    name: activity_45score_45detailtpPeeDI4feMeta?.name ?? "admin-modal-activity-score-detail",
    path: activity_45score_45detailtpPeeDI4feMeta?.path ?? "/admin/modal/activity-score-detail",
    meta: activity_45score_45detailtpPeeDI4feMeta || {},
    alias: activity_45score_45detailtpPeeDI4feMeta?.alias || [],
    redirect: activity_45score_45detailtpPeeDI4feMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/modal/activity-score-detail.vue").then(m => m.default || m)
  },
  {
    name: activity_45score_45listjmjIaAqEigMeta?.name ?? "admin-modal-activity-score-list",
    path: activity_45score_45listjmjIaAqEigMeta?.path ?? "/admin/modal/activity-score-list",
    meta: activity_45score_45listjmjIaAqEigMeta || {},
    alias: activity_45score_45listjmjIaAqEigMeta?.alias || [],
    redirect: activity_45score_45listjmjIaAqEigMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/modal/activity-score-list.vue").then(m => m.default || m)
  },
  {
    name: business_45modalLBsrUsfWW4Meta?.name ?? "admin-modal-business-modal",
    path: business_45modalLBsrUsfWW4Meta?.path ?? "/admin/modal/business-modal",
    meta: business_45modalLBsrUsfWW4Meta || {},
    alias: business_45modalLBsrUsfWW4Meta?.alias || [],
    redirect: business_45modalLBsrUsfWW4Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/modal/business-modal.vue").then(m => m.default || m)
  },
  {
    name: indexQ3SFD1KAetMeta?.name ?? "admin-other-info",
    path: indexQ3SFD1KAetMeta?.path ?? "/admin/other-info",
    meta: indexQ3SFD1KAetMeta || {},
    alias: indexQ3SFD1KAetMeta?.alias || [],
    redirect: indexQ3SFD1KAetMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/other-info/index.vue").then(m => m.default || m)
  },
  {
    name: screen_45setting8Mbuh1Gad9Meta?.name ?? "admin-other-info-screen-setting",
    path: screen_45setting8Mbuh1Gad9Meta?.path ?? "/admin/other-info/screen-setting",
    meta: screen_45setting8Mbuh1Gad9Meta || {},
    alias: screen_45setting8Mbuh1Gad9Meta?.alias || [],
    redirect: screen_45setting8Mbuh1Gad9Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/other-info/screen-setting.ts").then(m => m.default || m)
  },
  {
    name: list7WgzL6oXWpMeta?.name ?? "admin-project-components-list",
    path: list7WgzL6oXWpMeta?.path ?? "/admin/project/components/list",
    meta: list7WgzL6oXWpMeta || {},
    alias: list7WgzL6oXWpMeta?.alias || [],
    redirect: list7WgzL6oXWpMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/project/components/list.vue").then(m => m.default || m)
  },
  {
    name: searchMT2buZI8JfMeta?.name ?? "admin-project-components-search",
    path: searchMT2buZI8JfMeta?.path ?? "/admin/project/components/search",
    meta: searchMT2buZI8JfMeta || {},
    alias: searchMT2buZI8JfMeta?.alias || [],
    redirect: searchMT2buZI8JfMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/project/components/search.vue").then(m => m.default || m)
  },
  {
    name: indexS5UF2X3uaAMeta?.name ?? "admin-project",
    path: indexS5UF2X3uaAMeta?.path ?? "/admin/project",
    meta: indexS5UF2X3uaAMeta || {},
    alias: indexS5UF2X3uaAMeta?.alias || [],
    redirect: indexS5UF2X3uaAMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/project/index.vue").then(m => m.default || m)
  },
  {
    name: merge_45table_45containerZMfjqSYbVpMeta?.name ?? "admin-user-components-merge-table-container",
    path: merge_45table_45containerZMfjqSYbVpMeta?.path ?? "/admin/user/components/merge-table-container",
    meta: merge_45table_45containerZMfjqSYbVpMeta || {},
    alias: merge_45table_45containerZMfjqSYbVpMeta?.alias || [],
    redirect: merge_45table_45containerZMfjqSYbVpMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/merge-table-container.vue").then(m => m.default || m)
  },
  {
    name: indexYr8ufy3mwAMeta?.name ?? "admin-user-components-other-info",
    path: indexYr8ufy3mwAMeta?.path ?? "/admin/user/components/other-info",
    meta: indexYr8ufy3mwAMeta || {},
    alias: indexYr8ufy3mwAMeta?.alias || [],
    redirect: indexYr8ufy3mwAMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/other-info/index.vue").then(m => m.default || m)
  },
  {
    name: screen_45settingwemJeiVsMeMeta?.name ?? "admin-user-components-other-info-screen-setting",
    path: screen_45settingwemJeiVsMeMeta?.path ?? "/admin/user/components/other-info/screen-setting",
    meta: screen_45settingwemJeiVsMeMeta || {},
    alias: screen_45settingwemJeiVsMeMeta?.alias || [],
    redirect: screen_45settingwemJeiVsMeMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/other-info/screen-setting.ts").then(m => m.default || m)
  },
  {
    name: index9EpLQyFeYKMeta?.name ?? "admin-user-components-table-merge",
    path: index9EpLQyFeYKMeta?.path ?? "/admin/user/components/table-merge",
    meta: index9EpLQyFeYKMeta || {},
    alias: index9EpLQyFeYKMeta?.alias || [],
    redirect: index9EpLQyFeYKMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/components/table-merge/index.vue").then(m => m.default || m)
  },
  {
    name: indexTNtkLgDDSRMeta?.name ?? "admin-user",
    path: indexTNtkLgDDSRMeta?.path ?? "/admin/user",
    meta: indexTNtkLgDDSRMeta || {},
    alias: indexTNtkLgDDSRMeta?.alias || [],
    redirect: indexTNtkLgDDSRMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: normalizeel8ud9dp5GMeta?.name ?? "admin-user-normalize",
    path: normalizeel8ud9dp5GMeta?.path ?? "/admin/user/normalize",
    meta: normalizeel8ud9dp5GMeta || {},
    alias: normalizeel8ud9dp5GMeta?.alias || [],
    redirect: normalizeel8ud9dp5GMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/normalize.ts").then(m => m.default || m)
  },
  {
    name: settingTmbCALSlEHMeta?.name ?? "admin-user-setting",
    path: settingTmbCALSlEHMeta?.path ?? "/admin/user/setting",
    meta: settingTmbCALSlEHMeta || {},
    alias: settingTmbCALSlEHMeta?.alias || [],
    redirect: settingTmbCALSlEHMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/admin/user/setting.ts").then(m => m.default || m)
  },
  {
    name: constantsoaHSiljH8FMeta?.name ?? "application-common-constants",
    path: constantsoaHSiljH8FMeta?.path ?? "/application/common/constants",
    meta: constantsoaHSiljH8FMeta || {},
    alias: constantsoaHSiljH8FMeta?.alias || [],
    redirect: constantsoaHSiljH8FMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/application/common/constants.ts").then(m => m.default || m)
  },
  {
    name: settingsrbqpgMMmoeMeta?.name ?? "application-common-settings",
    path: settingsrbqpgMMmoeMeta?.path ?? "/application/common/settings",
    meta: settingsrbqpgMMmoeMeta || {},
    alias: settingsrbqpgMMmoeMeta?.alias || [],
    redirect: settingsrbqpgMMmoeMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/application/common/settings.ts").then(m => m.default || m)
  },
  {
    name: utilsVHtYjnkI2eMeta?.name ?? "application-common-utils",
    path: utilsVHtYjnkI2eMeta?.path ?? "/application/common/utils",
    meta: utilsVHtYjnkI2eMeta || {},
    alias: utilsVHtYjnkI2eMeta?.alias || [],
    redirect: utilsVHtYjnkI2eMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/application/common/utils.ts").then(m => m.default || m)
  },
  {
    name: indexeLcsuN75mMMeta?.name ?? "application-components-application-form",
    path: indexeLcsuN75mMMeta?.path ?? "/application/components/application-form",
    meta: indexeLcsuN75mMMeta || {},
    alias: indexeLcsuN75mMMeta?.alias || [],
    redirect: indexeLcsuN75mMMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/application/components/application-form/index.vue").then(m => m.default || m)
  },
  {
    name: index6IIbXirAxsMeta?.name ?? "application-components-application-notice",
    path: index6IIbXirAxsMeta?.path ?? "/application/components/application-notice",
    meta: index6IIbXirAxsMeta || {},
    alias: index6IIbXirAxsMeta?.alias || [],
    redirect: index6IIbXirAxsMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/application/components/application-notice/index.vue").then(m => m.default || m)
  },
  {
    name: indexHeCOGZoiLxMeta?.name ?? "application",
    path: indexHeCOGZoiLxMeta?.path ?? "/application",
    meta: indexHeCOGZoiLxMeta || {},
    alias: indexHeCOGZoiLxMeta?.alias || [],
    redirect: indexHeCOGZoiLxMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/application/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yrcc7HFKqOMeta?.name ?? "content-id",
    path: _91id_93yrcc7HFKqOMeta?.path ?? "/content/:id()",
    meta: _91id_93yrcc7HFKqOMeta || {},
    alias: _91id_93yrcc7HFKqOMeta?.alias || [],
    redirect: _91id_93yrcc7HFKqOMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/content/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Uuk8q5VyhxMeta?.name ?? "content-post-id",
    path: _91id_93Uuk8q5VyhxMeta?.path ?? "/content/post/:id()",
    meta: _91id_93Uuk8q5VyhxMeta || {},
    alias: _91id_93Uuk8q5VyhxMeta?.alias || [],
    redirect: _91id_93Uuk8q5VyhxMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/content/post/[id].vue").then(m => m.default || m)
  },
  {
    name: credit_45tablehS9PKX5NNVMeta?.name ?? "credit-components-credit-table",
    path: credit_45tablehS9PKX5NNVMeta?.path ?? "/credit/components/credit-table",
    meta: credit_45tablehS9PKX5NNVMeta || {},
    alias: credit_45tablehS9PKX5NNVMeta?.alias || [],
    redirect: credit_45tablehS9PKX5NNVMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/credit/components/credit-table.vue").then(m => m.default || m)
  },
  {
    name: indexI2BgtghFdoMeta?.name ?? "credit",
    path: indexI2BgtghFdoMeta?.path ?? "/credit",
    meta: indexI2BgtghFdoMeta || {},
    alias: indexI2BgtghFdoMeta?.alias || [],
    redirect: indexI2BgtghFdoMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/credit/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MoTx4btO8YMeta?.name ?? "credit-post-id",
    path: _91id_93MoTx4btO8YMeta?.path ?? "/credit/post/:id()",
    meta: _91id_93MoTx4btO8YMeta || {},
    alias: _91id_93MoTx4btO8YMeta?.alias || [],
    redirect: _91id_93MoTx4btO8YMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/credit/post/[id].vue").then(m => m.default || m)
  },
  {
    name: placard_45dialogX8UIYm7mHRMeta?.name ?? "credit-post-components-placard-dialog",
    path: placard_45dialogX8UIYm7mHRMeta?.path ?? "/credit/post/components/placard-dialog",
    meta: placard_45dialogX8UIYm7mHRMeta || {},
    alias: placard_45dialogX8UIYm7mHRMeta?.alias || [],
    redirect: placard_45dialogX8UIYm7mHRMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/credit/post/components/placard-dialog.vue").then(m => m.default || m)
  },
  {
    name: companyRl4pvetbneMeta?.name ?? "index-components-company",
    path: companyRl4pvetbneMeta?.path ?? "//components/company",
    meta: companyRl4pvetbneMeta || {},
    alias: companyRl4pvetbneMeta?.alias || [],
    redirect: companyRl4pvetbneMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/company.vue").then(m => m.default || m)
  },
  {
    name: infinite_45listYmWlxadOELMeta?.name ?? "index-components-infinite-list",
    path: infinite_45listYmWlxadOELMeta?.path ?? "//components/infinite-list",
    meta: infinite_45listYmWlxadOELMeta || {},
    alias: infinite_45listYmWlxadOELMeta?.alias || [],
    redirect: infinite_45listYmWlxadOELMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/infinite-list.vue").then(m => m.default || m)
  },
  {
    name: party_45linerTBvroL9eRMeta?.name ?? "index-components-party-line",
    path: party_45linerTBvroL9eRMeta?.path ?? "//components/party-line",
    meta: party_45linerTBvroL9eRMeta || {},
    alias: party_45linerTBvroL9eRMeta?.alias || [],
    redirect: party_45linerTBvroL9eRMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/party-line.vue").then(m => m.default || m)
  },
  {
    name: rulesQ17jGKFw01Meta?.name ?? "index-components-rules",
    path: rulesQ17jGKFw01Meta?.path ?? "//components/rules",
    meta: rulesQ17jGKFw01Meta || {},
    alias: rulesQ17jGKFw01Meta?.alias || [],
    redirect: rulesQ17jGKFw01Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/rules.vue").then(m => m.default || m)
  },
  {
    name: services_45listQ8EEcGAPcPMeta?.name ?? "index-components-services-list",
    path: services_45listQ8EEcGAPcPMeta?.path ?? "//components/services-list",
    meta: services_45listQ8EEcGAPcPMeta || {},
    alias: services_45listQ8EEcGAPcPMeta?.alias || [],
    redirect: services_45listQ8EEcGAPcPMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/index/components/services-list.vue").then(m => m.default || m)
  },
  {
    name: index1ucIzz74iPMeta?.name ?? "index",
    path: index1ucIzz74iPMeta?.path ?? "/",
    meta: index1ucIzz74iPMeta || {},
    alias: index1ucIzz74iPMeta?.alias || [],
    redirect: index1ucIzz74iPMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexTgWxjaqIo4Meta?.name ?? "login",
    path: indexTgWxjaqIo4Meta?.path ?? "/login",
    meta: indexTgWxjaqIo4Meta || {},
    alias: indexTgWxjaqIo4Meta?.alias || [],
    redirect: indexTgWxjaqIo4Meta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexiKEb0fHJahMeta?.name ?? "register",
    path: indexiKEb0fHJahMeta?.path ?? "/register",
    meta: indexiKEb0fHJahMeta || {},
    alias: indexiKEb0fHJahMeta?.alias || [],
    redirect: indexiKEb0fHJahMeta?.redirect,
    component: () => import("/var/jenkins_home/workspace/city-property-rating-web/pages/register/index.vue").then(m => m.default || m)
  }
]