/*
 * @Author: LiZhiWei
 * @Date: 2024-06-06 09:40:58
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-06-11 09:44:12
 * @Description:
 */
import { stylesStrategies, stylesMapping } from './constants'

export const executeStylesStrategy = (fieldName: string) => {
  let strategy = stylesMapping[fieldName]
  if (!strategy) {
    strategy = 'default'
  }
  return stylesStrategies[strategy]()
}
