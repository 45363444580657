<!--
 * @Author: wjc
 * @Date: 2023-11-15 09:54:21
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-06 14:38:21
 * @Description: 
-->
<template>
  <NuxtLayout>
    <el-config-provider :size="elConfig.size" :z-index="elConfig.zIndex" :locale="zhCn">
      <NuxtPage />
    </el-config-provider>
  </NuxtLayout>
</template>

<script setup lang="ts">
  import zhCn from 'element-plus/es/locale/lang/zh-cn'
  import { appName } from '~/constants'

  useHead({
    title: appName,
  })

  const elConfig = ref({
    size: 'default',
    zIndex: 300,
  })
</script>

<style lang="scss">
  @use '~/assets/scss/index.scss';
  @import url('element-plus/dist/index.css');
  @import url('element-plus/theme-chalk/display.css');

  html {
    /**
    一般浏览器的默认字体大小为 16px，以10px 为基数计算rem，font-size: 1.4rem = 14px;
    注意：此设置在媒体查询中无效，媒体查询中，基数还是16px
  */
    font-size: 62.5%;
  }

  html,
  body,
  #__nuxt {
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>
