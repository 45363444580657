/*
 * @Author: mzr
 * @Date: 2024-03-06 17:05:48
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-06-11 15:58:04
 * @Description:
 */
export const screenFormOption = [
  {
    title: '物业企业大屏',
    children: [
      {
        title: '企业补充信息',
        children: [
          {
            title: '企业类型',
            type: 'select',
            key: 'enterpriseType',
            option: [
              { name: '国有企业', value: 'nationalized' },
              { name: '集体企业', value: 'collective' },
              { name: '股份合作企业', value: 'jointstockCooperative' },
              { name: '联营企业', value: 'associated' },
              { name: '有限责任公司', value: 'limitedLiability' },
              { name: '股份有限公司', value: 'corporation' },
              { name: '私营企业', value: 'private' },
              { name: '其他内资企业', value: 'otherDomestic' },
              { name: '港澳台商投资企业', value: 'HKMCTWInvestment' },
              { name: '外商投资企业', value: 'foreigninvested' },
            ],
          },
          {
            title: '企业所在地',
            type: 'select',
            key: 'enterpriseLocation',
            option: [
              { name: '美兰区', value: 'ML' },
              { name: '龙华区', value: 'LH' },
              { name: '秀英区', value: 'XY' },
              { name: '琼山区', value: 'QS' },
            ],
          },
          {
            title: '企业信用级别',
            type: 'select',
            key: 'enterpriseCreditLevel',
            option: [
              { name: '一级信用', value: '1' },
              { name: '二级信用', value: '2' },
              { name: '三级信用', value: '3' },
              { name: '四级信用', value: '4' },
              { name: '五级信用', value: '5' },
            ],
          },
          {
            title: '企业经营类型',
            type: 'select',
            key: 'enterpriseCategory',
            option: [
              { name: '主营物业服务企业', value: 'main' },
              { name: '兼营物业服务企业', value: 'part' },
            ],
          },
          {
            title: '企业资质级别',
            type: 'select',
            key: 'enterpriseQualificationGrade',
            option: [
              { name: '一级', value: '1' },
              { name: '二级', value: '2' },
              { name: '三级', value: '3' },
              { name: '暂定', value: '3Tentative' },
            ],
          },
          {
            title: '执行会计制度',
            type: 'select',
            key: 'accountingSystem',
            option: [
              { name: '企业会计制度', value: 'enterprise' },
              { name: '事业单位会计制度', value: 'publicInstitution' },
              { name: '行政单位会计制度', value: 'administrativeUnit' },
              { name: '其他', value: 'other' },
            ],
          },
          {
            title: '物业协会会员类型',
            type: 'select',
            key: 'associationMembershipType',
            option: [
              { name: '会员单位', value: 'member' },
              { name: '理事单位', value: 'governingBody' },
              { name: '副会长单位', value: 'vicePresident' },
              { name: '会长单位', value: 'president' },
            ],
          },
        ],
      },
    ],
  },
  {
    title: '物业企业从业人员大屏',
    children: [
      {
        title: '企业从业总人数',
        children: [
          {
            title: '总员工数',
            type: 'input',
            key: 'enterpriseEmployeeTotal',
          },
        ],
      },
      {
        title: '企业人员岗位信息',
        children: [
          {
            title: '秩序维护人员数',
            type: 'input',
            key: 'orderlyMaintenance',
          },
          {
            title: '绿化人员数',
            type: 'input',
            key: 'green',
          },
          {
            title: '物业管家人员数',
            type: 'input',
            key: 'housekeeper',
          },
          {
            title: '保洁人员数',
            type: 'input',
            key: 'cleaning',
          },
          {
            title: '设备维护人员数',
            type: 'input',
            key: 'equipmentMaintenance',
          },
          {
            title: '项目经理人员数',
            type: 'input',
            key: 'projectManager',
          },
        ],
      },
      {
        title: '企业人员性别信息',
        children: [
          {
            title: '男性人数',
            type: 'input',
            key: 'men',
          },
          {
            title: '女性人数',
            type: 'input',
            key: 'women',
          },
        ],
      },
      {
        title: '企业人员年龄结构',
        children: [
          {
            title: '青年人数(39岁以下)',
            type: 'input',
            key: 'practitionerYoung',
          },
          {
            title: '中年人数(40-59岁)',
            type: 'input',
            key: 'practitionerMiddle',
          },
          {
            title: '老年人数(60岁以上)',
            type: 'input',
            key: 'practitionerElderly',
          },
        ],
      },
      {
        title: '企业人员学历信息',
        children: [
          {
            title: '初中及以下学历人数',
            type: 'input',
            key: 'practitionerJuniorHighBelow',
          },
          {
            title: '高中及中专学历人数',
            type: 'input',
            key: 'practitionerHighPostSecondary',
          },
          {
            title: '大专学历人数',
            type: 'input',
            key: 'practitionerCollege',
          },
          {
            title: '本科学历人数',
            type: 'input',
            key: 'practitionerBachelor',
          },
          {
            title: '硕士学历人数',
            type: 'input',
            key: 'practitionerGraduate',
          },
          {
            title: '博士学历人数',
            type: 'input',
            key: 'practitionerDoctoral',
          },
        ],
      },
      {
        title: '企业人员持证信息',
        children: [
          {
            title: '物业管理员上岗人数',
            type: 'input',
            key: 'propertyManager',
          },
          {
            title: '物业部门经理上岗证人数',
            type: 'input',
            key: 'propertyDepartmentManager',
          },
          {
            title: '物业企业经理上岗证人数',
            type: 'input',
            key: 'propertyEnterpriseManager',
          },
          {
            title: '持其它专业上岗证人数',
            type: 'input',
            key: 'otherSpecialty',
          },
        ],
      },
    ],
  },
  {
    title: '党建引领大屏',
    children: [
      {
        title: '企业党员信息',
        children: [
          {
            title: '党支部数量',
            type: 'input',
            key: 'partyBranch',
          },
          {
            title: '正式党员人数',
            type: 'input',
            key: 'official',
          },
          {
            title: '预备党员人数',
            type: 'input',
            key: 'preparatory',
          },
          {
            title: '入党积极分子人数',
            type: 'input',
            key: 'activist',
          },
        ],
      },
      {
        title: '党员党龄信息',
        children: [
          {
            title: '5年以下人数',
            type: 'input',
            key: 'partyAge05',
          },
          {
            title: '5-10年人数',
            type: 'input',
            key: 'partyAge510',
          },
          {
            title: '10-15年人数',
            type: 'input',
            key: 'partyAge1015',
          },
          {
            title: '15-20年人数',
            type: 'input',
            key: 'partyAge1520',
          },
          {
            title: '20年以上人数',
            type: 'input',
            key: 'partyAge20',
          },
        ],
      },
      {
        title: '党员年龄信息',
        children: [
          {
            title: '青年人数(39岁以下)',
            type: 'input',
            key: 'partyYoung',
          },
          {
            title: '中年人数(40-59岁)',
            type: 'input',
            key: 'partyMiddle',
          },
          {
            title: '老年人数(60岁以上)',
            type: 'input',
            key: 'partyElderly',
          },
        ],
      },
      {
        title: '党员学历信息',
        children: [
          {
            title: '初中及以下学历人数',
            type: 'input',
            key: 'partyJuniorHighBelow',
          },
          {
            title: '高中及中专学历人数',
            type: 'input',
            key: 'partyHighPostSecondary',
          },
          {
            title: '大专学历人数',
            type: 'input',
            key: 'partyCollege',
          },
          {
            title: '本科学历人数',
            type: 'input',
            key: 'partyBachelor',
          },
          {
            title: '硕士学历人数',
            type: 'input',
            key: 'partyGraduate',
          },
          {
            title: '博士学历人数',
            type: 'input',
            key: 'partyDoctoral',
          },
        ],
      },
      {
        title: '党员民族信息',
        children: [
          {
            title: '汉族人数',
            type: 'input',
            key: 'han',
          },
          {
            title: '少数民族人数',
            type: 'input',
            key: 'shao',
          },
        ],
      },
    ],
  },
  {
    title: '设施设备大屏',
    children: [
      {
        title: '企业电梯系统情况',
        children: [
          {
            title: '直梯数量',
            type: 'input',
            key: 'elevatorStraight',
          },
          {
            title: '扶梯数量',
            type: 'input',
            key: 'elevatorEscalator',
          },
          {
            title: '电梯机房数量',
            type: 'input',
            key: 'elevatorRoom',
          },
        ],
      },
      {
        title: '企业消防系统情况',
        children: [
          {
            title: '消防泵数量',
            type: 'input',
            key: 'fireProtectionFirePump',
          },
          {
            title: '喷淋泵数量',
            type: 'input',
            key: 'fireProtectionSprayPump',
          },
          {
            title: '稳压泵数量',
            type: 'input',
            key: 'fireProtectionPressureRegulatorPump',
          },
          {
            title: '消防主机数量',
            type: 'input',
            key: 'fireProtectionMainframe',
          },
          {
            title: '风机数量',
            type: 'input',
            key: 'fireProtectionFan',
          },
          {
            title: '消防水池(箱)数量',
            type: 'input',
            key: 'fireProtectionPool',
          },
          {
            title: '室外消防栓数量',
            type: 'input',
            key: 'fireProtectionOutdoorHydrant',
          },
          {
            title: '楼道消防栓数量',
            type: 'input',
            key: 'fireProtectionBuildingHydrant',
          },
          {
            title: '灭火器数量',
            type: 'input',
            key: 'fireProtectionExtinguisher',
          },
          {
            title: '喷淋头数量',
            type: 'input',
            key: 'fireProtectionSprinkler',
          },
          {
            title: '烟感器数量',
            type: 'input',
            key: 'fireProtectionSmokeDetector',
          },
          {
            title: '消防控制室数量',
            type: 'input',
            key: 'fireProtectionControl',
          },
        ],
      },
      {
        title: '企业给排水系统',
        children: [
          {
            title: '压力罐数量',
            type: 'input',
            key: 'waterSupplyDrainagePressure',
          },
          {
            title: '生活水泵数量',
            type: 'input',
            key: 'waterSupplyDrainageDomesticPump',
          },
          {
            title: '水泵房数量',
            type: 'input',
            key: 'waterSupplyDrainagePumpRoom',
          },
          {
            title: '污水井数量',
            type: 'input',
            key: 'waterSupplyDrainageSewageWell',
          },
          {
            title: '雨水井数量',
            type: 'input',
            key: 'waterSupplyDrainageRainWell',
          },
          {
            title: '排水泵数量',
            type: 'input',
            key: 'waterSupplyDrainageDrainagePump',
          },
        ],
      },
      {
        title: '企业配电系统',
        children: [
          {
            title: '配电房数量',
            type: 'input',
            key: 'powerDistributionDistributionRoom',
          },
          {
            title: '发电机房数量',
            type: 'input',
            key: 'powerDistributionGeneratorRoom',
          },
          {
            title: '高压柜数量',
            type: 'input',
            key: 'powerDistributionHighVoltageCabinet',
          },
          {
            title: '低压柜数量',
            type: 'input',
            key: 'powerDistributionLowVoltageCabinet',
          },
          {
            title: '变压器数量',
            type: 'input',
            key: 'powerDistributionTransformer',
          },
          {
            title: '发电机数量',
            type: 'input',
            key: 'powerDistributionGenerator',
          },
        ],
      },
    ],
  },
  {
    title: '智能化设备大屏',
    children: [
      {
        title: '企业基本智能设备情况',
        children: [
          {
            title: '远程电表数量',
            type: 'input',
            key: 'controlElectricMeter',
          },
          {
            title: '远程水表数量',
            type: 'input',
            key: 'controlWaterMeter',
          },
          {
            title: '绿化自动喷淋数量',
            type: 'input',
            key: 'controlSpray',
          },
          {
            title: '远程灯数量',
            type: 'input',
            key: 'controlLight',
          },
          {
            title: '远程配电房传感器数量',
            type: 'input',
            key: 'controlDistributionRoom',
          },
          {
            title: '远程水泵房传感器数量',
            type: 'input',
            key: 'controlWaterPumpRoom',
          },
          {
            title: '远程电梯传感器数量',
            type: 'input',
            key: 'controlElevatorRoom',
          },
          {
            title: '环境监测设备数量',
            type: 'input',
            key: 'controlEnvironmentalMonitoring',
          },
        ],
      },
      {
        title: '企业智能监控设备情况',
        children: [
          {
            title: '闭路监控摄像头数量',
            type: 'input',
            key: 'surveillanceCamera',
          },
          {
            title: '闭路监控主机数量',
            type: 'input',
            key: 'surveillanceHost',
          },
          {
            title: '闭路监控显示器数量',
            type: 'input',
            key: 'surveillanceDisplay',
          },
        ],
      },
      {
        title: '企业智能安防设备情况',
        children: [
          {
            title: '单元门禁数量',
            type: 'input',
            key: 'securityAccessControl',
          },
          {
            title: '人脸识别机数量',
            type: 'input',
            key: 'securityFaceRecognition',
          },
          {
            title: '大门摆闸数量',
            type: 'input',
            key: 'securityGate',
          },
          {
            title: '车辆道闸数量',
            type: 'input',
            key: 'securityDrivewayGate',
          },
          {
            title: '道闸主机数量',
            type: 'input',
            key: 'securityGateHost',
          },
        ],
      },
      {
        title: '企业智能充电桩情况',
        children: [
          {
            title: '电动自行车充电桩数量',
            type: 'input',
            key: 'chargerBicycle',
          },
          {
            title: '新能源车充电桩数量',
            type: 'input',
            key: 'chargerCar',
          },
        ],
      },
    ],
  },
]
