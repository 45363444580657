/*
 * @Author: LiZhiWei
 * @Date: 2024-05-09 15:02:03
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-06-21 09:28:17
 * @Description:
 */
import type { IInfo } from '~/models/activity'
export const menus: IInfo[] = [
  {
    id: '入会程序及会员须知',
    name: '入会程序及会员须知',
  },
  {
    id: '入会申请',
    name: '入会申请',
  },
]

export const stylesStrategies = {
  colStyles: function () {
    return { class: 'col-item-col', span: 4, labelSpan: 24, contentSpan: 24 }
  },

  largeRowSpan16Styles: function () {
    return { class: 'col-item-row-l', span: 16, labelSpan: 6, contentSpan: 18 }
  },

  largeRowSpan24Styles: function () {
    return { class: 'col-item-row-l', span: 24, labelSpan: 4, contentSpan: 20 }
  },

  projectNameRowStyles: function () {
    return { class: 'col-item-row-xl', span: 24, labelSpan: 4, contentSpan: 20 }
  },

  addressRowStyles: function () {
    return { class: 'col-item-col-address ', span: 24, labelSpan: 4, contentSpan: 20 }
  },
  textRowStyles: function () {
    return { class: 'col-item-col-text', span: 4, labelSpan: 24, contentSpan: 0 }
  },
  textRowMStyles: function () {
    return { class: 'col-item-row-m', span: 8, labelSpan: 12, contentSpan: 12 }
  },
  default: function () {
    return { class: 'col-item-row-s', span: 8, labelSpan: 12, contentSpan: 12 }
  },
}

export const stylesMapping = {
  manageResidenceProjectNum: 'colStyles',
  manageOfficeProjectNum: 'colStyles',
  manageIndustrialProjectNum: 'colStyles',
  manageBusinessProjectNum: 'colStyles',
  manageOtherProjectNum: 'colStyles',
  manageResidenceProjectArea: 'colStyles',
  manageOfficeProjectArea: 'colStyles',
  manageIndustrialProjectArea: 'colStyles',
  manageBusinessProjectArea: 'colStyles',
  manageOtherProjectArea: 'colStyles',
  enterpriseName: 'largeRowSpan16Styles',
  entUscc: 'largeRowSpan16Styles',
  entEmail: 'largeRowSpan16Styles',
  membershipApplicationOpinion: 'largeRowSpan24Styles',
  associationReviewOpinion: 'largeRowSpan24Styles',
  remark: 'largeRowSpan24Styles',
  manageProjectName: 'projectNameRowStyles',
  address: 'addressRowStyles',
  projectCategoryAndNum: 'textRowStyles',
  projectCategoryAndArea: 'textRowStyles',
  cityDemonstrationProjectNum: 'textRowMStyles',
  provincialDemonstrationProjectNum: 'textRowMStyles',
  nationalDemonstrationProjectNum: 'textRowMStyles',
}
