/*
 * @Author: ChenYaJin
 * @Date: 2023-07-03 10:46:54
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-08-06 08:27:56
 * @Description: 企业实体模型
 */

import { useOptionStore } from '~/stores/modules/option'
import { Project } from './project'
import { SnapshotType } from './enums'

export interface ChunkList<T = unknown> {
  id: string | number
  children: T[]
}

export interface EnterpriseCase {
  image: string
  jumpLink: string
  name: string
}

// 企业员工
export interface IEnterpriseEmployee {
  leaderNum?: number // 企业高层管理人员数量
  projectManagerNum?: number // 管理处主任(项目经理)数量
  repairmanNum?: number // 维修员数量
  administratorNum?: number // 管理员数量
  cleanerNum?: number // 清洁工数量
  greeningStaffNum?: number // 绿化工作员数量
  otherNum?: number // 其他工种数量
  outsourcedNum?: number // 外包人数量
  payInsuranceNum?: number // 企业五项社会保险缴纳人数
  juniorCollegeNum?: number // 大专及以上人员数量
}

export interface IUploadInfo {
  guarantee?: string // 真实性承诺书
  // convention?: string // 行业自律公约
}
export interface IEnterprise extends IEnterpriseEmployee, IUploadInfo {
  id?: string
  name?: string
  level?: string
  license?: string
  registrationDate?: string
  entUscc?: string // 统一社会信用代码
  initialSigning?: string // 初始开展物业服务时间
  corpn?: string // 法定代表人
  employeesNum?: number
  mobile?: string
  districtCode?: string
  cityCode?: string
  provinceCode?: string
  address?: string
  auditStatus?: string
  association?: string // 物业协会
  reviewBy?: string
  score?: number
  reject?: string // 理由
  reviewAt?: string
  deadlineAt?: string
  approvedCommunityNum?: number
  partyBranch?: boolean | number
}

export class Enterprise implements IEnterprise {
  id?: string
  name?: string
  level?: string
  license?: string
  registrationDate?: string
  entUscc?: string
  initialSigning?: string
  corpn?: string
  employeesNum?: number
  mobile?: string
  guarantee?: string
  // convention?: string
  districtCode?: string
  cityCode?: string
  provinceCode?: string
  address?: string
  auditStatus?: string
  association?: string
  annualReceivable?: number
  reviewBy?: string
  score?: number
  reject?: string
  reviewAt?: string
  deadlineAt?: string
  approvedCommunityNum?: number // 审核通过的项目数量
  leaderNum?: number
  projectManagerNum?: number
  repairmanNum?: number
  administratorNum?: number
  cleanerNum?: number
  greeningStaffNum?: number
  otherNum?: number
  outsourcedNum?: number
  payInsuranceNum?: number
  juniorCollegeNum?: number
  partyBranch?: boolean | number

  constructor(json: IEnterprise = {}) {
    this.id = json.id
    this.name = json.name
    this.level = json.level
    this.license = json.license || ''
    this.registrationDate = json.registrationDate || ''
    this.entUscc = json.entUscc || ''
    this.initialSigning = json.initialSigning || ''
    this.corpn = json.corpn || ''
    this.employeesNum = json.employeesNum || 0
    this.mobile = json.mobile || ''
    this.guarantee = json.guarantee || ''
    // this.convention = json.convention || ''
    this.districtCode = json.districtCode || ''
    this.cityCode = json.cityCode || ''
    this.provinceCode = json.provinceCode || ''
    this.address = json.address || ''
    this.auditStatus = json.auditStatus || ''
    this.association = json.association || ''
    this.reviewBy = json.reviewBy || ''
    this.score = json.score || 0
    this.reject = json.reject || ''
    this.leaderNum = json.leaderNum || 0
    this.projectManagerNum = json.projectManagerNum || 0
    this.repairmanNum = json.repairmanNum || 0
    this.administratorNum = json.administratorNum || 0
    this.cleanerNum = json.cleanerNum || 0
    this.greeningStaffNum = json.greeningStaffNum || 0
    this.otherNum = json.otherNum || 0
    this.outsourcedNum = json.outsourcedNum || 0
    this.payInsuranceNum = json.payInsuranceNum || 0
    this.juniorCollegeNum = json.juniorCollegeNum || 0
    this.partyBranch = Boolean(json.partyBranch)
    this.approvedCommunityNum = json.approvedCommunityNum || 0
    this.reviewAt = json.reviewAt
    this.deadlineAt = json.deadlineAt
  }

  get history() {
    let year = 0
    if (this.registrationDate) {
      const startTime = new Date(this.registrationDate)
      const endTime = new Date()
      year = endTime.getFullYear() - startTime.getFullYear()
    }
    return year
  }
}

export interface IEnterpriseMessage {
  id: string
  type: SnapshotType
  auditStatus?: string
  score?: string
  createdAt?: string
  deadlineAt?: string
  reviewBy?: string
  reviewAt?: string
}

export class EnterpriseMessage implements IEnterpriseMessage {
  id: string
  type: SnapshotType
  auditStatus?: string
  score?: string
  createdAt?: string
  deadlineAt?: string
  reviewBy?: string
  reviewAt?: string

  constructor(json: IEnterpriseMessage) {
    this.id = json.id
    this.type = json.type
    this.auditStatus = json.auditStatus
    this.score = json.score
    this.createdAt = json.createdAt
    this.deadlineAt = json.deadlineAt
    this.reviewBy = json.reviewBy
    this.reviewAt = json.reviewAt
  }

  get auditStatusText() {
    const optionStore = useOptionStore()
    return optionStore.getStatusName(this.auditStatus || '')
  }
}
