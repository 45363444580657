/**
 *  企业注册-表单
 * setting - 表单配置数据
 * formData - 表单初始数据
 * formOptions - 表单选项数据
 */
import { ETableItemType } from '@/models/enums'
import type { IEnterprise, IEnterpriseEmployee } from '@/models/enterprise'
import type { ITableSetting, mergeTable } from '@/models/table-setting'

const tableSettingToFill: ITableSetting<IEnterprise> = {
  setting: [
    [
      {
        label: '企业名称',
        fieldName: 'name',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请填写企业名称',
        placeholder: '请填写企业名称',
      },
      {
        label: '所属物业协会',
        fieldName: 'association',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '请输入所属物业行业协会',
        placeholder: '请输入所属物业行业协会, 未加入无需填写',
      },
    ],
    [
      {
        label: '企业营业执照',
        fieldName: 'license',
        type: ETableItemType.UPLOAD_IMAGE,
        required: true,
        requiredMessage: '请上传营业执照',
        placeholder: '支持上传jpeg、jpg、png格式图片，大小不超过10M',
      },
    ],
    [
      {
        label: '统一社会信用代码',
        fieldName: 'entUscc',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请填写统一社会信用代码',
        placeholder: '请填写企业营业执照上的信用代码',
      },
      {
        label: '工商注册时间',
        fieldName: 'registrationDate',
        type: ETableItemType.DATE,
        required: true,
        requiredMessage: '请选择企业营业执照上的成立时间',
        placeholder: '请选择企业营业执照上的成立时间',
      },
    ],
    [
      {
        label: '初始开展物业服务时间',
        fieldName: 'initialSigning',
        type: ETableItemType.DATE,
        meta: {
          dateType: 'month',
          valueFormat: 'YYYY-MM',
        },
        required: true,
        requiredMessage: '请选择企业承接首个物业管理项目的时间',
        placeholder: '企业承接首个物业管理项目的时间',
      },
      {
        label: '是否成立党支部',
        fieldName: 'partyBranch',
        type: ETableItemType.RADIO,
        required: true,
        requiredMessage: '请选择是否成立党支部',
        placeholder: '是否成立党支部',
      },
    ],
    [
      {
        label: '法定代表人',
        fieldName: 'corpn',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请填写法定代表人姓名',
        placeholder: '请填写法定代表人姓名',
      },
      {
        label: '企业联系电话',
        fieldName: 'mobile',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请填写企业或联系人的联系电话，固话或手机',
        placeholder: '请填写企业或联系人的联系电话，固话或手机',
      },
    ],
    [
      {
        label: '企业区域',
        fieldName: 'provinceCode',
        type: ETableItemType.ADDRESS_AREA,
        required: true,
        requiredMessage: '请填写当前企业的实际营业地址',
        placeholder: '请填写当前企业的实际营业地址',
      },
      {
        label: '企业地址',
        fieldName: 'address',
        type: ETableItemType.ADDRESS_DETAIL,
        required: true,
        requiredMessage: '请输入企业详细地址',
        placeholder: '请输入企业详细地址',
      },
    ],
  ],
  formData: {
    name: '',
    association: '',
    license: '',
    entUscc: '',
    registrationDate: '',
    initialSigning: '',
    corpn: '',
    mobile: '',
    address: '',
    provinceCode: '',
    cityCode: '',
    districtCode: '',
  },
  formOptions: {},
}

const mergeTableSetting: mergeTable<IEnterpriseEmployee> = {
  setting: [
    [
      {
        label: '企业人数（不含外包）',
        row: 1,
        key: '1-1',
        fieldName: '1-1',
        bgColor: '#F6FDFB',
        isBottomLine: true,
        setting: [
          [
            {
              label: '经营管理人员',
              row: 1,
              key: '1-2',
              fieldName: '1-2',
              bgColor: '#F6FDFB',
              setting: [
                [
                  {
                    label: '企业高层管理人员',
                    row: 1,
                    key: '1-3-1',
                    fieldName: '1-3-1',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-1',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'leaderNum',
                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                  {
                    label: '管理处主任(项目经理)',
                    row: 1,
                    key: '1-3-2',
                    fieldName: '1-3-2',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-2',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'projectManagerNum',
                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                  {
                    label: '管理员',
                    row: 1,
                    key: '1-3-3',
                    fieldName: '1-3-3',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-3',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'administratorNum',

                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                ],
              ],
            },
            {
              label: '企业操作人员',
              row: 1,
              key: '1-2-2',
              fieldName: '1-2-2',
              bgColor: '#F6FDFB',
              setting: [
                [
                  {
                    label: '工程维修员',
                    row: 1,
                    key: '1-3-4',
                    fieldName: '1-3-4',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-4',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'repairmanNum',
                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                  {
                    label: '清洁工',
                    row: 1,
                    key: '8',
                    fieldName: '1-3-5',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-5',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'cleanerNum',
                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                  {
                    label: '绿化工',
                    row: 1,
                    key: '1-3-6',
                    fieldName: '1-3-6',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-6',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'greeningStaffNum',
                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                  {
                    label: '其他工种',
                    row: 1,
                    key: '1-3-7',
                    fieldName: '1-3-7',
                    bgColor: '#fff',
                    setting: [
                      [
                        {
                          row: 1,
                          key: '1-4-7',
                          type: ETableItemType.INPUT,
                          meta: {
                            type: 'number',
                          },
                          fieldName: 'otherNum',
                          required: true,
                          placeholder: '请输入人数',
                        },
                      ],
                    ],
                  },
                ],
              ],
            },
          ],
        ],
      },
    ],
    [
      {
        label: '外包人数',
        row: 3,
        key: '2-1',
        fieldName: '2-1',
        bgColor: '#fff',
        isMargin: true,
        isBottomLine: true,
        setting: [
          [
            {
              row: 1,
              key: '2-2',
              type: ETableItemType.INPUT,
              meta: {
                type: 'number',
              },
              fieldName: 'outsourcedNum',
              required: true,
              placeholder: '请输入人数',
            },
          ],
        ],
      },
    ],
    [
      {
        label: '学历分类（不含外包）',
        row: 2,
        key: '3-1',
        fieldName: '3-1',
        bgColor: '#F6FDFB',
        isBottomLine: true,
        setting: [
          [
            {
              label: '大专及以上人员数量',
              row: 1,
              key: '3-2',
              fieldName: '3-2',
              bgColor: '#fff',
              setting: [
                [
                  {
                    row: 1,
                    key: '3-3',
                    type: ETableItemType.INPUT,
                    meta: {
                      type: 'number',
                    },
                    fieldName: 'juniorCollegeNum',
                    required: true,
                    placeholder: '请输入人数',
                  },
                ],
              ],
            },
          ],
        ],
      },
    ],
    [
      {
        label: '保险缴纳情况（不含外包）',
        row: 2,
        key: '4-1',
        fieldName: '4-1',
        bgColor: '#F6FDFB',
        isBottomLine: true,
        rowEnd: true,
        setting: [
          [
            {
              label: '企业五项社会保险缴纳人数',
              row: 1,
              key: '4-2',
              fieldName: '4-2',
              bgColor: '#fff',
              setting: [
                [
                  {
                    row: 1,
                    key: '4-3',
                    type: ETableItemType.INPUT,
                    meta: {
                      type: 'number',
                    },
                    fieldName: 'payInsuranceNum',
                    required: true,
                    placeholder: '请输入人数',
                  },
                ],
              ],
            },
          ],
        ],
      },
    ],
  ],
  formData: {
    leaderNum: 0, // 企业高层管理人员数量
    projectManagerNum: 0, // (项目经理)数量
    repairmanNum: 0, // 维修员数量
    administratorNum: 0, // 管理员数量
    cleanerNum: 0, // 清洁工数量
    greeningStaffNum: 0, // 绿化工作员数量
    otherNum: 0, // 其他工种数量
    outsourcedNum: 0, // 外包人数量
    payInsuranceNum: 0, // 企业五项社会保险缴纳人数
    juniorCollegeNum: 0, // 大专及以上人员数量
  },
}
export { tableSettingToFill, mergeTableSetting }
