/*
 * @Author: wjc
 * @Date: 2023-05-23 15:14:09
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-08 17:53:06
 * @Description:
 */
import defaultSettings from '~/constants'

// accessToken 一天内有效
const tokenName = defaultSettings.defaultSettings.cookieTokenName
// refreshToken 一个月内有效
const refreshTokenName = defaultSettings.defaultSettings.cookieRefreshTokenName
// 企业ID - key
const enterpriseUserIdName = defaultSettings.defaultSettings.enterpriseUserIdName
// UUID - key
const uuIdName = defaultSettings.defaultSettings.uuidName

/**
 *accessToken
 * token刷新机制：accessToken失效则用refreshToken获取最新的token, refreshToken失效则需重新登陆
 */
export function getToken() {
  if (process.browser) {
    return localStorage.getItem(tokenName)
  }
  return ''
}

export function setToken(token: string) {
  if (process.browser) {
    return localStorage.setItem(tokenName, token)
  }
  return ''
}

export function removeToken() {
  if (process.browser) {
    return localStorage.removeItem(tokenName)
  }
  return ''
}

/**
 *refreshToken
 */
export function getRefreshToken() {
  if (process.browser) {
    return localStorage.getItem(refreshTokenName)
  }
  return ''
}

export function setRefreshToken(token: string) {
  if (process.browser) {
    return localStorage.setItem(refreshTokenName, token)
  }
  return ''
}

export function removeRefreshToken() {
  if (process.browser) {
    return localStorage.removeItem(refreshTokenName)
  }
  return ''
}

/**
 * 登录者企业ID
 */
export function setEnterpriseUserIdSave(id: string) {
  return localStorage.setItem(enterpriseUserIdName, id)
}

export function getEnterpriseUserIdSave() {
  return localStorage.getItem(enterpriseUserIdName)
}

export function removeEnterpriseUserIdSave() {
  return localStorage.removeItem(enterpriseUserIdName)
}

/**
 * 登录者UUID
 */
export function setUuIdSave(id: string) {
  return localStorage.setItem(uuIdName, id)
}

export function getUuIdSave() {
  return localStorage.getItem(uuIdName)
}

export function removeUuIdSave() {
  return localStorage.removeItem(uuIdName)
}

/**
 * 删除所有本地缓存信息
 */
export function removeAllLocalStorage() {
  removeToken()
  removeUuIdSave()
  removeEnterpriseUserIdSave()
}
