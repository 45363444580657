/*
 * @Author: ChenYaJin
 * @Date: 2023-07-18 11:15:16
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-05-21 11:02:14
 * @Description: 数据序列化
 */
import { Enterprise, type IEnterprise } from '@/models/enterprise'

export const normalizePostEnterpriseData = (enterprise: Enterprise): IEnterprise => {
  const {
    id,
    name,
    association,
    license,
    entUscc,
    registrationDate,
    initialSigning,
    corpn,
    mobile,
    guarantee,
    // convention,
    provinceCode,
    cityCode,
    districtCode,
    address,
    leaderNum,
    projectManagerNum,
    repairmanNum,
    administratorNum,
    cleanerNum,
    greeningStaffNum,
    otherNum,
    outsourcedNum,
    payInsuranceNum,
    juniorCollegeNum,
    partyBranch,
  } = enterprise
  return {
    id,
    name,
    association,
    license,
    entUscc,
    registrationDate: registrationDate ? registrationDate + ' 00:00:00' : '',
    initialSigning: initialSigning ? initialSigning + '-01 00:00:00' : '',
    corpn,
    mobile,
    guarantee,
    // convention,
    provinceCode,
    cityCode,
    districtCode,
    address,
    leaderNum: Number(leaderNum),
    projectManagerNum: Number(projectManagerNum),
    repairmanNum: Number(repairmanNum),
    administratorNum: Number(administratorNum),
    cleanerNum: Number(cleanerNum),
    greeningStaffNum: Number(greeningStaffNum),
    otherNum: Number(otherNum),
    outsourcedNum: Number(outsourcedNum),
    payInsuranceNum: Number(payInsuranceNum),
    juniorCollegeNum: Number(juniorCollegeNum),
    partyBranch: Number(partyBranch),
  }
}

export const getEnterpriseFormsData = (enterprise: Enterprise) => {
  const {
    auditStatus,
    reviewBy,
    score,
    reject,
    reviewAt,
    deadlineAt,
    guarantee,
    // convention,
    leaderNum,
    projectManagerNum,
    repairmanNum,
    administratorNum,
    cleanerNum,
    greeningStaffNum,
    otherNum,
    outsourcedNum,
    payInsuranceNum,
    juniorCollegeNum,
  } = enterprise
  return {
    info: enterprise,
    auditStatus: {
      auditStatus,
      reviewBy,
      score,
      reject,
      content: '',
      reviewAt,
      deadlineAt,
    },
    employee: {
      leaderNum: Number(leaderNum),
      projectManagerNum: Number(projectManagerNum),
      repairmanNum: Number(repairmanNum),
      administratorNum: Number(administratorNum),
      cleanerNum: Number(cleanerNum),
      greeningStaffNum: Number(greeningStaffNum),
      otherNum: Number(otherNum),
      outsourcedNum: Number(outsourcedNum),
      payInsuranceNum: Number(payInsuranceNum),
      juniorCollegeNum: Number(juniorCollegeNum),
    },
    upload: {
      guarantee,
      // convention,
    },
  }
}
