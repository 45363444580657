/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 14:27:52
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-07 16:37:31
 * @Description: 枚举相关api
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse } from '@/models/common'
import type { IOption } from '@/models/common'

export type ImagesList = {
  id?: string
  fileId?: string
  base64?: string
  name?: string
  url?: string
}
/**
 * 省列表
 * @returns
 */
export function getProvinceList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/open/enterprise/provinces',
    method: 'get',
  })
}

/**
 * 市列表
 * @returns
 */
export function getCityList(provinceId: string) {
  return http.request<BasicResponse<Array<IOption>>>({
    url: `/open/enterprise/citys`,
    method: 'get',
    params: {
      provinceId: provinceId,
    },
  })
}

/**
 * 区列表
 * @returns
 */
export function getDistrictList(cityId: string) {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/open/enterprise/districts',
    method: 'get',
    params: {
      cityId: cityId,
    },
  })
}

/**
 * 评分等级枚举
 * @returns
 */
export function getLevelList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/open/enterprise/levelEnum',
    method: 'get',
  })
}

/**
 * 业务类型枚举
 */
export function getBusinessTyeList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/reviewApplication/typeEnum',
    method: 'get',
  })
}
/**
 * 审核状态枚举
 * @returns
 */
export function getStatusList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/reviewApplication/statusEnum',
    method: 'get',
  })
}

/**
 * 项目性质一级枚举
 * @returns
 */
export function getProjectTypeList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/open/enterprise/typeEnum',
    method: 'get',
  })
}
/**
 * 项目性质二级枚举-住宅
 * @returns
 */
export function getProjectTypeSecondHouse() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/open/enterprise/residenceBelowTypeEnum',
    method: 'get',
  })
}
/**
 * 项目性质二级枚举-其他
 * @returns
 */
export function getProjectTypeSecondOther() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/open/enterprise/otherBelowTypeEnum',
    method: 'get',
  })
}
/**
 * 项目参与方式一级枚举
 * @returns
 */
export function getProjectFirstWayList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/community/wayEnum',
    method: 'get',
  })
}
/**
 * 项目参与方式二级枚举
 * @returns
 */
export function getProjectSecondWayList() {
  return http.request<BasicResponse<Array<IOption>>>({
    url: '/community/belowWayEnum',
    method: 'get',
  })
}

/**
 * 获取图片base64 单个
 * @returns
 */
export function getImageBase64(id: string) {
  return http.request<BasicResponse<ImagesList>>({
    url: `/file/images/${id}`,
    method: 'get',
  })
}

/**
 * 获取图片base64 多个
 * @returns
 */
export function getImagesBase64(ids: string) {
  return http.request<BasicResponse<Array<ImagesList>>>({
    url: `/file/images`,
    params: {
      ids,
    },
    method: 'get',
  })
}
