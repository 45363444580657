import { useLoginStore } from '~/stores/modules/user/login'
import { getEnterpriseUserIdSave } from '~/utils/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path.startsWith('/admin')) {
    const loginStore = useLoginStore()
    const isLogin = loginStore.isLogin || getEnterpriseUserIdSave()
    if (!isLogin) {
      return navigateTo('/')
    }
  }
})
