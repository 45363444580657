/*
 * @Author: ChenYaJin
 * @Date: 2023-07-18 18:07:39
 * @LastEditors: wjc
 * @LastEditTime: 2023-11-21 11:28:55
 * @Description: 枚举
 */
import { type IOption } from '@/models/common'
import {
  getProvinceList,
  getLevelList,
  getStatusList,
  getProjectTypeList,
  getProjectTypeSecondHouse,
  getProjectTypeSecondOther,
  getProjectFirstWayList,
  getProjectSecondWayList,
  getBusinessTyeList,
} from '@/api/options'

const statusTypeList = [
  { code: 'review', type: 'warning', color: '#FF952C' },
  { code: 'approved', type: 'success', color: '#299132' },
  { code: 'failed', type: 'danger', color: '#FE2F5C' },
]
export interface OptionState {
  menus: any[] // 菜单
  currentMenu: string // 当前菜单
  provinces: IOption[] // 省份列表
  levelList: IOption[] // 等级枚举
  statusList: IOption[] // 审核状态枚举
  projectType: IOption[] // 项目性质一级
  projectTypeHouse: IOption[] // 项目性质二级住宅
  projectTypeOther: IOption[] // 项目性质二级其他
  projectWayFirst: IOption[] // 项目参与方式一级
  projectWaySecond: IOption[] // 项目参与方式二级
  businessType: IOption[] // 业务类型枚举
}

export const useOptionStore = defineStore({
  id: 'option',
  state: (): OptionState => {
    return {
      menus: [],
      currentMenu: '',
      provinces: [],
      levelList: [],
      statusList: [],
      projectType: [],
      projectTypeHouse: [],
      projectTypeOther: [],
      projectWayFirst: [],
      projectWaySecond: [],
      businessType: [],
    }
  },
  getters: {},
  actions: {
    getProvinceListInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.provinces.length) {
          resolve(this.provinces)
        } else {
          getProvinceList()
            .then((res) => {
              this.provinces = res.data.map((item) => {
                item.type = 'province'
                return item
              })
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getLevelListInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.levelList.length) {
          resolve(this.levelList)
        } else {
          getLevelList()
            .then((res) => {
              this.levelList = res.data.map((item) => {
                item.name = item.code
                return item
              })
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getStatusListInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.statusList.length) {
          resolve(this.statusList)
        } else {
          getStatusList()
            .then((res) => {
              this.statusList = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getStatusName(code: string): string {
      const target = this.statusList.find((item) => item.code === code)
      return target?.name || ''
    },
    getStatusTagType(code: string): string {
      const target = statusTypeList.find((item) => item.code === code)
      return target?.type || ''
    },
    getStatusTagColor(code: string): string {
      const target = statusTypeList.find((item) => item.code === code)
      return target?.color || ''
    },
    getBusinessTypeListInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.businessType.length) {
          resolve(this.businessType)
        } else {
          getBusinessTyeList()
            .then((res) => {
              this.businessType = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getBusinessTypeName(code: string): string {
      const target = this.businessType.find((item) => item.code === code)
      return target?.name || ''
    },
    getProjectTypeInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.projectType.length) {
          resolve(this.projectType)
        } else {
          getProjectTypeList()
            .then((res) => {
              this.projectType = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getProjectTypeName(code: string): string {
      const target = this.projectType.find((item) => item.code === code)
      return target?.name || ''
    },
    getProjectTypeHouseName(code: string): string {
      const target = this.projectTypeHouse.find((item) => item.code === code)
      return target?.name || ''
    },
    getProjectTypeOtherName(code: string): string {
      const target = this.projectTypeOther.find((item) => item.code === code)
      return target?.name || ''
    },
    getProjectTypeHouseInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.projectTypeHouse.length) {
          resolve(this.projectTypeHouse)
        } else {
          getProjectTypeSecondHouse()
            .then((res) => {
              this.projectTypeHouse = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getProjectTypeOtherInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.projectTypeOther.length) {
          resolve(this.projectTypeOther)
        } else {
          getProjectTypeSecondOther()
            .then((res) => {
              this.projectTypeOther = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getProjectWayFirstInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.projectWayFirst.length) {
          resolve(this.projectWayFirst)
        } else {
          getProjectFirstWayList()
            .then((res) => {
              this.projectWayFirst = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
    getProjectWaySecondInfo(): Promise<IOption[]> {
      return new Promise((resolve, reject) => {
        if (this.projectWaySecond.length) {
          resolve(this.projectWaySecond)
        } else {
          getProjectSecondWayList()
            .then((res) => {
              this.projectWaySecond = res.data
              resolve(res.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
  },
})
