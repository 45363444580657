/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 14:28:24
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-09 09:57:44
 * @Description: 当前企业用户api
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse } from '~/models/common'
import { Enterprise, type IEnterprise } from '~/models/enterprise'

/**
 * 获取当前企业用户的详细信息
 * @returns
 */
export function getEnterpriseUser() {
  return http.request<BasicResponse<Enterprise>>({
    url: '/enterprise/detail',
    method: 'get',
  })
}

/**
 * 填写当前企业用户信息
 * @param data Enterprise
 * @returns
 */
export function postEnterpriseUser(data: IEnterprise) {
  return http.request<BasicResponse<number>>({
    url: `/enterprise`,
    method: 'post',
    data,
  })
}

/**
 * 修改当前企业用户信息
 * @param data Enterprise
 * @returns
 */
export function putEnterpriseUser(id: string, data: IEnterprise) {
  return http.request<BasicResponse<number>>({
    url: `/enterprise/${id}`,
    method: 'put',
    data,
  })
}
