import { http } from '~/utils/fetch/index'
import type { BasicResponse } from '@/models/common'

export interface IDraft {
  type?: string
  bind?: string
  content: string
}

export function postDraft(data: IDraft) {
  return http.request<BasicResponse<any>>({
    url: '/draft',
    method: 'post',
    data,
  })
}

export function getDraft(params: any) {
  return http.request<BasicResponse<any>>({
    url: '/draft',
    method: 'get',
    params,
  })
}

export function getDraftEnum() {
  return http.request<BasicResponse<any>>({
    url: '/draft/typeEnum',
    method: 'get',
  })
}
