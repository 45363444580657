/*
 * @Author: LiZhiWei
 * @Date: 2024-08-01 18:00:54
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-09-26 09:14:11
 * @Description:
 */
import type { ITableSetting } from '@/models/table-setting'
import { ETableItemType } from '@/models/enums'
import { testMobile, testPhone } from '~/utils'
import type { IFormValidateCallback } from '~/models/common'
import type { fileType } from '~/models/activity'

export const formSettings: ITableSetting<any> = {
  setting: [
    [
      {
        label: '企业名称',
        fieldName: 'enterpriseName',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入企业名称',
        placeholder: '请输入企业名称',
      },
    ],
    [
      {
        label: '项目名称',
        fieldName: 'communityId',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择参与活动的项目',
        placeholder: '请选择参与活动的项目',
      },
    ],
    [
      {
        label: '项目地址',
        fieldName: 'projectAddress',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入项目地址',
        placeholder: '请输入项目地址',
      },
    ],
    [
      {
        label: '住宅小区',
        fieldName: 'isResidence',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '别墅',
        fieldName: 'isVilla',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '写字楼',
        fieldName: 'isOffice',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '商场',
        fieldName: 'isBusiness',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '学校',
        fieldName: 'isSchool',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '医院',
        fieldName: 'isHospital',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '展馆',
        fieldName: 'isVenue',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '工业区',
        fieldName: 'isIndustrial',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '其他',
        fieldName: 'isOther',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请选择项目类型',
        placeholder: '请选择项目类型',
      },
    ],
    [
      {
        label: '建筑面积',
        fieldName: 'projectArea',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入建筑面积',
        placeholder: '请输入建筑面积',
      },
    ],
    [
      {
        label: '企业负责人',
        fieldName: 'enterprisePrincipal',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入企业负责人',
        placeholder: '请输入企业负责人',
      },
      {
        label: '电话',
        fieldName: 'enterprisePrincipalPhone',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入企业负责人电话',
        placeholder: '请输入企业负责人电话',
        validator: (rule, value, callback) => {
          if (!value) {
            callback('请输入企业负责人电话')
          } else if (!(testMobile(value) || testPhone(value))) {
            callback('请输入正确的电话号码')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '项目负责人',
        fieldName: 'projectPrincipal',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入项目负责人',
        placeholder: '请输入项目负责人',
      },
      {
        label: '电话',
        fieldName: 'projectPrincipalPhone',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入项目负责人电话',
        validator: (rule, value, callback) => {
          if (!value) {
            callback('请输入项目负责人电话')
          } else if (!(testMobile(value) || testPhone(value))) {
            callback('请输入正确的电话号码')
          }
          callback()
        },
        placeholder: '请输入项目负责人电话',
      },
    ],
    [
      {
        label: '住宅',
        fieldName: 'residenceServiceCharge',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入住宅服务费标准',
        placeholder: '请输入住宅服务费标准',
      },
      {
        label: '非住宅',
        fieldName: 'notResidenceServiceCharge',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入非住宅服务费标准',
        placeholder: '请输入非住宅服务费标准',
      },
    ],
    [
      {
        label: '申报自述',
        fieldName: 'declareRecount',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入申报自述',
        placeholder: '请输入申报自述',
      },
    ],
    [
      {
        label: '各区物业管理行政部门推荐',
        fieldName: 'recommend',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '请输入各区物业管理行政部门推荐',
        placeholder: '请输入各区物业管理行政部门推荐',
      },
    ],
  ],
}

export const ExcellenceUploadFilesSettings: ITableSetting<any> = {
  setting: [
    [
      {
        label: '申报备案表',
        fieldName: 'municipalExcellenceDeclare',
        type: ETableItemType.UPLOAD_FILE,
        required: true,
        requiredMessage: '请上传申报备案表',
        placeholder: '上传申报备案表，大小不超过10M!',
        validator: (_rule: unknown, value: fileType, callback: IFormValidateCallback) => {
          if (!value || !value.id) {
            callback('请上传申报备案表')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '企业营业执照',
        fieldName: 'businessLicense',
        type: ETableItemType.UPLOAD_FILE,
        required: true,
        requiredMessage: '请上传企业营业执照',
        placeholder: '上传营业执照复印件，大小不超过10M!',
        validator: (_rule: unknown, value: fileType, callback: IFormValidateCallback) => {
          if (!value || !value.id) {
            callback('请上传企业营业执照')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '项目依法取得的有关资料',
        fieldName: 'legalObtainList',
        type: ETableItemType.UPLOAD_FILE,
        meta: {
          maxLimit: 5,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传项目依法取得的有关资料',
        placeholder: '如：中标通知书、住建局招投标备案通知书等，大小不超过10M!',
        validator: (_rule: unknown, value: fileType[], callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传项目依法取得的有关资料')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '项目物业服务合同',
        fieldName: 'propertyContractList',
        type: ETableItemType.UPLOAD_FILE,
        meta: {
          maxLimit: 5,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传项目物业服务合同',
        placeholder: '上传项目物业服务合同，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传项目物业服务合同')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '项目概况介绍',
        fieldName: 'overviewList',
        type: ETableItemType.UPLOAD_FILE,
        meta: {
          maxLimit: 5,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传项目概况介绍',
        placeholder:
          '上传项目概况介绍以及能反映项目外观、形象的照片若干张，请整合到一份pdf中一起上传，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传项目概况介绍')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '项目收房率情况说明',
        fieldName: 'occupancyRateList',
        type: ETableItemType.UPLOAD_FILE,
        meta: {
          maxLimit: 5,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传项目收房率情况说明',
        placeholder: '上传项目收房率情况说明，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传项目收房率情况说明')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '工程项目相关资料',
        fieldName: 'engineeringList',
        type: ETableItemType.UPLOAD_FILE,
        meta: {
          maxLimit: 5,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传工程项目相关资料',
        placeholder:
          '上传项目总平面图、工程规划许可证（正件）、建筑工程竣工综合验收备案证、建设工程消防验收意见书，图片格式请整合到一份pdf中一起上传，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传工程项目相关资料')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '项目创优实施方案和汇报材料',
        fieldName: 'excellenceList',
        type: ETableItemType.UPLOAD_FILE,
        meta: {
          maxLimit: 5,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传项目创优实施方案和汇报材料',
        placeholder: '如：创优工作组织开展情况、自查自检情况以及整改措施等，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传项目创优实施方案和汇报材料')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '承诺函',
        fieldName: 'commitment',
        type: ETableItemType.UPLOAD_FILE,
        required: true,
        requiredMessage: '请上传承诺函',
        placeholder: '上传承诺函，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value || value.length === 0) {
            callback('请上传承诺函')
          }
          callback()
        },
      },
    ],
  ],
}
