/*
 * @Author: ChenYaJin
 * @Date: 2023-07-10 17:00:25
 * @LastEditors: wjc
 * @LastEditTime: 2023-11-30 15:08:59
 * @Description: 登录api
 */
import { http } from '~/utils/fetch/index'
import type { ILogin, ILoginToken, IAccount } from '@/models/login'
import type { BasicResponse } from '@/models/common'

/**
 * 登录
 * @param data ILogin
 * @returns
 */
export function postLogin(data: ILogin) {
  return http.request<BasicResponse<ILoginToken>>({
    url: '/login',
    method: 'post',
    data,
  })
}

/**
 * 注册
 * http://yapi.wisdomcity.com.cn/project/136/interface/api/21953
 * @param data IAccount
 * @returns
 */
export function postRegister(data: IAccount) {
  return http.request<BasicResponse<string>>({
    url: '/ent/auth/register',
    method: 'post',
    data,
  })
}

/**
 * 获取新的token
 * @param data clientId 设备指纹
 * @returns
 */
export function postRefreshToken(data: { clientId: string }) {
  return http.request<BasicResponse<ILoginToken>>({
    url: '/auth/refresh',
    method: 'post',
    data,
  })
}

/**
 * 获取账号信息
 */
export function getAccountInfo(uuid: string) {
  return http.request<BasicResponse<IAccount>>({
    url: `/ent/auth/account/${uuid}`,
    method: 'get',
  })
}
/**
 * 修改密码
 */
export function postAccountInfo(data: IAccount) {
  return http.request<BasicResponse<boolean>>({
    url: `/ent/auth/account`,
    method: 'put',
    data: data,
  })
}
