/*
 * @Author: ChenYaJin
 * @Date: 2023-07-20 09:29:00
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-07-26 16:10:07
 * @Description: 数据序列化
 */
import { BehaviorAction, type IBehaviorAction } from '@/models/behavior'
import { type IEmployee, Employee } from '@/models/employee'
import { type IProject, Project } from '@/models/project'

export function postProjectDataNormalize(project: Project): IProject {
  const {
    name,
    type,
    belowType,
    way,
    belowWay,
    supportability,
    insured,
    annualReceivable,
    amateurIncome,
    address,
    contractStartAt,
    contractEndAt,
    elevatorsTotal,
    housesTotal,
    extent,
    staffTotal,
    ownerCommittee,
    ownerCommitteeAt,
    startingAt,
    enterpriseId,
    contract,
    provinceCode,
    cityCode,
    districtCode,
  } = project
  return {
    name,
    type,
    belowType,
    way,
    belowWay,
    supportability: Number(supportability),
    insured: Number(insured),
    annualReceivable: Number(annualReceivable),
    amateurIncome: Number(amateurIncome),
    address,
    contractStartAt: contractStartAt ? contractStartAt.replace(' 00:00:00', '') + ' 00:00:00' : '',
    contractEndAt: contractEndAt ? contractEndAt.replace(' 00:00:00', '') + ' 00:00:00' : '',
    elevatorsTotal: Number(elevatorsTotal),
    housesTotal: Number(housesTotal),
    extent: Number(extent),
    staffTotal: Number(staffTotal),
    ownerCommittee: ownerCommittee ? 1 : 0, // 是否成立业委会
    ownerCommitteeAt: ownerCommitteeAt?.replace(' 00:00:00', '') + ' 00:00:00', // 业委会成立时间
    startingAt: startingAt?.replace(' 00:00:00', '') + ' 00:00:00',
    enterpriseId,
    contract,
    provinceCode,
    cityCode,
    districtCode,
  }
}

export function postEmployeeDataNormalize(employee: Employee): IEmployee {
  const { name, identityCardNum, documentPhotoList } = employee
  return { name, identityCardNum, documentPhotoList }
}

export function postIBehaviorDataNormalize(employee: BehaviorAction): IBehaviorAction {
  const { sketch, recognitionOrgan, recognition, provePhotoList } = employee
  return {
    sketch,
    recognitionOrgan,
    recognition: recognition?.replace(' 00:00:00', '')
      ? recognition.replace(' 00:00:00', '') + ' 00:00:00'
      : '',
    provePhotoList,
  }
}
