/*
 * @Author: LiZhiWei
 * @Date: 2024-05-08 17:56:59
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-06-21 09:06:18
 * @Description: 申请入会表单设置
 */
import type { ITableSetting } from '@/models/table-setting'
import { ETableItemType } from '@/models/enums'
import type { IFormValidateCallback } from '@/models/common'
import { testMobile, testPhone, checkEmail } from '@/utils'
export const applicationFormSetting: ITableSetting<any> = {
  setting: [
    [
      {
        label: '企业名称',
        fieldName: 'enterpriseName',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请填写企业名称',
        placeholder: '请填写企业名称',
      },
      {
        label: '会员证号',
        fieldName: 'membershipNumber',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '',
        placeholder: '',
      },
    ],
    [
      {
        label: '社会信用代码',
        fieldName: 'entUscc',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请填写社会信用代码',
        placeholder: '请填写社会信用代码',
      },
      {
        label: '推荐人\n(姓名或公司简称)',
        fieldName: 'referrer',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '推荐人',
        placeholder: '推荐人',
      },
    ],
    [
      {
        label: '通讯地址',
        fieldName: 'address',
        type: ETableItemType.ADDRESS,
        required: true,
        requiredMessage: '请输入项目通讯地址',
        placeholder: '请输入项目通讯地址',
      },
    ],
    [
      {
        label: '企业电话',
        fieldName: 'entPhone',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '请输入企业电话',
        placeholder: '请输入企业电话',
      },
      {
        label: '企业邮箱',
        fieldName: 'entEmail',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '请输入企业邮箱',
        placeholder: '请输入企业邮箱',
      },
    ],
    [
      {
        label: '法定代表人',
        fieldName: 'corpn',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入法定代表人',
        placeholder: '请输入法定代表人',
      },
      {
        label: '联系电话',
        fieldName: 'corpnPhone',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入联系电话',
        placeholder: '请输入联系电话',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testPhone(value)
          if (!value) {
            callback(new Error('电话不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('电话格式不正确!'))
          }
        },
      },
      {
        label: '手机',
        fieldName: 'corpnMobile',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入手机',
        placeholder: '请输入手机',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testMobile(value)
          if (!value) {
            callback(new Error('手机不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('手机格式不正确!'))
          }
        },
      },
    ],
    [
      {
        label: '总经理/经理',
        fieldName: 'manager',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入总经理/经理',
        placeholder: '请输入总经理/经理',
      },
      {
        label: '联系电话',
        fieldName: 'managerPhone',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入联系电话',
        placeholder: '请输入联系电话',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testPhone(value)
          if (!value) {
            callback(new Error('电话不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('电话格式不正确!'))
          }
        },
      },
      {
        label: '手机',
        fieldName: 'managerMobile',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入手机',
        placeholder: '请输入手机',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testMobile(value)
          if (!value) {
            callback(new Error('手机不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('手机格式不正确!'))
          }
        },
      },
    ],
    [
      {
        label: '联系人',
        fieldName: 'contact',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入联系人',
        placeholder: '请输入联系人',
      },
      {
        label: '联系电话',
        fieldName: 'contactPhone',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入联系电话',
        placeholder: '请输入联系电话',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testPhone(value)
          if (!value) {
            callback(new Error('电话不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('电话格式不正确!'))
          }
        },
      },
      {
        label: '手机',
        fieldName: 'contactMobile',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入手机',
        placeholder: '请输入手机',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testMobile(value)
          if (!value) {
            callback(new Error('手机不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('手机格式不正确!'))
          }
        },
      },
    ],
    [
      {
        label: '成立时间',
        fieldName: 'establish',
        type: ETableItemType.DATE,
        meta: {
          valueFormat: 'YYYY-MM-DD HH:mm:ss',
        },
        required: true,
        requiredMessage: '请输入成立时间',
        placeholder: '请输入成立时间',
      },
      {
        label: '企业类型',
        fieldName: 'entType',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入企业类型',
        placeholder: '请输入企业类型',
      },
      {
        label: '职工人数',
        fieldName: 'employeeNum',
        meta: {
          inputType: 'number',
        },
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入职工人数',
        placeholder: '请输入职工人数',
      },
    ],
    [
      {
        label: '在管项目\n名称',
        fieldName: 'manageProjectName',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'textarea',
        },
        required: true,
        requiredMessage: '请输入在管项目名称',
        placeholder: '请输入在管项目名称',
      },
    ],
    [
      {
        label: '在管项目\n类别及数量\n总计',
        fieldName: 'projectCategoryAndNum',
        type: ETableItemType.TEXT,
        required: true,
        requiredMessage: '请输入住宅小区数量',
        placeholder: '请输入住宅小区数量',
      },
      {
        label: '住宅小区\n(个)',
        fieldName: 'manageResidenceProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入住宅小区数量',
        placeholder: '请输入住宅小区数量',
      },
      {
        label: '大厦/写字楼\n(个)',
        fieldName: 'manageOfficeProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入大厦/写字楼数量',
        placeholder: '请输入大厦/写字楼数量',
      },
      {
        label: '工业区\n(个)',
        fieldName: 'manageIndustrialProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入工业区数量',
        placeholder: '请输入工业区数量',
      },
      {
        label: '商场/商铺\n(个)',
        fieldName: 'manageBusinessProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入商场/商铺数量',
        placeholder: '请输入商场/商铺数量',
      },
      {
        label: '其他物业\n(个)',
        fieldName: 'manageOtherProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入其他物业数量',
        placeholder: '请输入其他物业数量',
      },
    ],
    [
      {
        label: '在管项目\n类别及规模\n总计',
        fieldName: 'projectCategoryAndArea',
        type: ETableItemType.TEXT,
        required: true,
        requiredMessage: '',
        placeholder: '',
      },
      {
        label: '住宅小区\n(m²)',
        fieldName: 'manageResidenceProjectArea',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入住宅小区规模',
        placeholder: '请输入住宅小区规模',
      },
      {
        label: '大厦/写字楼\n(m²)',
        fieldName: 'manageOfficeProjectArea',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入大厦/写字楼规模',
        placeholder: '请输入大厦/写字楼规模',
      },
      {
        label: '工业区\n(m²)',
        fieldName: 'manageIndustrialProjectArea',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入工业区规模',
        placeholder: '请输入工业区规模',
      },
      {
        label: '商场/商铺\n(m²)',
        fieldName: 'manageBusinessProjectArea',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入商场/商铺规模',
        placeholder: '请输入商场/商铺规模',
      },
      {
        label: '其他物业\n(m²)',
        fieldName: 'manageOtherProjectArea',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入其他物业规模',
        placeholder: '请输入其他物业规模',
      },
    ],
    [
      {
        label: '市优秀示范\n项目数量\n(个)',
        fieldName: 'cityDemonstrationProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入市优秀示范项目数量',
        placeholder: '请输入市优秀示范项目数量',
      },
      {
        label: '省优秀示范\n项目数量\n(个)',
        fieldName: 'provincialDemonstrationProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入省优秀示范项目数量',
        placeholder: '请输入省优秀示范项目数量',
      },
      {
        label: '国家优秀示范\n项目数量\n(个)',
        fieldName: 'nationalDemonstrationProjectNum',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: true,
        requiredMessage: '请输入国家优秀示范项目数量',
        placeholder: '请输入国家优秀示范项目数量',
      },
    ],
    [
      {
        label: '会员入会申请\n意见',
        fieldName: 'membershipApplicationOpinion',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入会员入会申请意见',
        placeholder: '请输入会员入会申请意见',
      },
    ],
    [
      {
        label: '协会审核\n意见',
        fieldName: 'associationReviewOpinion',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '',
        placeholder: '',
      },
    ],
    [
      {
        label: '备 注',
        fieldName: 'remark',
        type: ETableItemType.INPUT,
        required: false,
        requiredMessage: '请输入备注',
        placeholder: '请输入备注',
      },
    ],
  ],
}
export const applicationUploadFilesSettings: ITableSetting<any> = {
  setting: [
    [
      {
        label: '企业营业执照',
        fieldName: 'businessLicense',
        type: ETableItemType.UPLOAD_IMAGE,
        required: true,
        requiredMessage: '请上传企业营业执照',
        placeholder: '支持上传jpeg、jpg、png格式图片，大小不超过10M!',
      },
    ],
    [
      {
        label: '法人身份证',
        fieldName: 'corporateIdentityCard',
        type: ETableItemType.UPLOAD_IMAGE,
        meta: {
          maxLimit: 2,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传法人身份证',
        placeholder: '请上传法人身份证正反面图片，支持上传jpeg、jpg、png格式图片，大小不超过10M!',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          if (!value) {
            callback('请上传身份证照片')
          } else if (value.length < 2) {
            callback('请上传身份证正反面照片')
          }
          callback()
        },
      },
    ],
    [
      {
        label: '诚信责任书',
        fieldName: 'integrityResponsibilityStatement',
        type: ETableItemType.UPLOAD_FILE,
        required: true,
        requiredMessage: '请上传诚信责任书',
        placeholder: '支持上传doc、docx格式文件，最多1份文件，大小不超过10M!',
      },
    ],
  ],
}
